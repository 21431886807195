@import './normalize.scss';

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../themes/DavidClulow/fonts/Montserrat-Regular.woff")
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url("../themes/DavidClulow/fonts/Montserrat-SemiBold.woff")
}

.fade-enter {
  z-index: 1;
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

@keyframes ldio-iygribuesa {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-iygribuesa div {
  left: 99px;
  top: 62px;
  position: absolute;
  animation: ldio-iygribuesa linear 1.5384615384615383s infinite;
  background: #82c6bd;
  width: 2px;
  height: 24px;
  border-radius: 0px / 0px;
  transform-origin: 1px 38px;
}.ldio-iygribuesa div:nth-child(1) {
   transform: rotate(0deg);
   animation-delay: -1.4854111405835542s;
   background: #82c6bd;
 }.ldio-iygribuesa div:nth-child(2) {
    transform: rotate(12.413793103448276deg);
    animation-delay: -1.4323607427055702s;
    background: #82c6bd;
  }.ldio-iygribuesa div:nth-child(3) {
     transform: rotate(24.82758620689655deg);
     animation-delay: -1.379310344827586s;
     background: #82c6bd;
   }.ldio-iygribuesa div:nth-child(4) {
      transform: rotate(37.241379310344826deg);
      animation-delay: -1.326259946949602s;
      background: #82c6bd;
    }.ldio-iygribuesa div:nth-child(5) {
       transform: rotate(49.6551724137931deg);
       animation-delay: -1.273209549071618s;
       background: #82c6bd;
     }.ldio-iygribuesa div:nth-child(6) {
        transform: rotate(62.06896551724138deg);
        animation-delay: -1.2201591511936338s;
        background: #82c6bd;
      }.ldio-iygribuesa div:nth-child(7) {
         transform: rotate(74.48275862068965deg);
         animation-delay: -1.1671087533156497s;
         background: #82c6bd;
       }.ldio-iygribuesa div:nth-child(8) {
          transform: rotate(86.89655172413794deg);
          animation-delay: -1.1140583554376657s;
          background: #82c6bd;
        }.ldio-iygribuesa div:nth-child(9) {
           transform: rotate(99.3103448275862deg);
           animation-delay: -1.0610079575596816s;
           background: #82c6bd;
         }.ldio-iygribuesa div:nth-child(10) {
            transform: rotate(111.72413793103448deg);
            animation-delay: -1.0079575596816976s;
            background: #82c6bd;
          }.ldio-iygribuesa div:nth-child(11) {
             transform: rotate(124.13793103448276deg);
             animation-delay: -0.9549071618037135s;
             background: #82c6bd;
           }.ldio-iygribuesa div:nth-child(12) {
              transform: rotate(136.55172413793105deg);
              animation-delay: -0.9018567639257293s;
              background: #82c6bd;
            }.ldio-iygribuesa div:nth-child(13) {
               transform: rotate(148.9655172413793deg);
               animation-delay: -0.8488063660477453s;
               background: #82c6bd;
             }.ldio-iygribuesa div:nth-child(14) {
                transform: rotate(161.3793103448276deg);
                animation-delay: -0.7957559681697612s;
                background: #82c6bd;
              }.ldio-iygribuesa div:nth-child(15) {
                 transform: rotate(173.79310344827587deg);
                 animation-delay: -0.7427055702917771s;
                 background: #82c6bd;
               }.ldio-iygribuesa div:nth-child(16) {
                  transform: rotate(186.20689655172413deg);
                  animation-delay: -0.689655172413793s;
                  background: #82c6bd;
                }.ldio-iygribuesa div:nth-child(17) {
                   transform: rotate(198.6206896551724deg);
                   animation-delay: -0.636604774535809s;
                   background: #82c6bd;
                 }.ldio-iygribuesa div:nth-child(18) {
                    transform: rotate(211.0344827586207deg);
                    animation-delay: -0.5835543766578248s;
                    background: #82c6bd;
                  }.ldio-iygribuesa div:nth-child(19) {
                     transform: rotate(223.44827586206895deg);
                     animation-delay: -0.5305039787798408s;
                     background: #82c6bd;
                   }.ldio-iygribuesa div:nth-child(20) {
                      transform: rotate(235.86206896551724deg);
                      animation-delay: -0.47745358090185674s;
                      background: #82c6bd;
                    }.ldio-iygribuesa div:nth-child(21) {
                       transform: rotate(248.27586206896552deg);
                       animation-delay: -0.42440318302387264s;
                       background: #82c6bd;
                     }.ldio-iygribuesa div:nth-child(22) {
                        transform: rotate(260.6896551724138deg);
                        animation-delay: -0.37135278514588854s;
                        background: #82c6bd;
                      }.ldio-iygribuesa div:nth-child(23) {
                         transform: rotate(273.1034482758621deg);
                         animation-delay: -0.3183023872679045s;
                         background: #82c6bd;
                       }.ldio-iygribuesa div:nth-child(24) {
                          transform: rotate(285.51724137931035deg);
                          animation-delay: -0.2652519893899204s;
                          background: #82c6bd;
                        }.ldio-iygribuesa div:nth-child(25) {
                           transform: rotate(297.9310344827586deg);
                           animation-delay: -0.21220159151193632s;
                           background: #82c6bd;
                         }.ldio-iygribuesa div:nth-child(26) {
                            transform: rotate(310.3448275862069deg);
                            animation-delay: -0.15915119363395225s;
                            background: #82c6bd;
                          }.ldio-iygribuesa div:nth-child(27) {
                             transform: rotate(322.7586206896552deg);
                             animation-delay: -0.10610079575596816s;
                             background: #82c6bd;
                           }.ldio-iygribuesa div:nth-child(28) {
                              transform: rotate(335.17241379310343deg);
                              animation-delay: -0.05305039787798408s;
                              background: #82c6bd;
                            }.ldio-iygribuesa div:nth-child(29) {
                               transform: rotate(347.58620689655174deg);
                               animation-delay: 0s;
                               background: #82c6bd;
                             }
.loadingio-spinner-spinner-482gvyi63m {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  //background: rgba(NaN, NaN, NaN, 0);
}
.ldio-iygribuesa {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-iygribuesa div { box-sizing: content-box; }
/* generated by https://loading.io/ */

.swiper-pagination {
  margin: 20px auto 40px auto;
}
